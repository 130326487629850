* {
  background-color: white;
}

.app {
  font-family: 'Roboto Mono', monospace;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  min-height: 100vh;
}

.content-wrapper {
  height: 100vh;
  margin-top: 10vh;
}

.header-content{
  font-size: 4rem;
  margin-top: 12vh;
}

.content {
  width: 100%;
}

.body-wrapper {
  display: flex;
  justify-content: center;
  gap: 5rem;
}

.body-content {
  display: flex;
  justify-content: center;
}

.header {
  text-align: center;
}

.header-wrapper {
}
