/* index.css */
ul li a {
  color: #000;
  background-color: none;
}

ul li a:hover {
  text-decoration: none;

}

.link {
  height: 1.5rem;
  width: 1.5rem;
  &:hover{
    text-decoration: none;
    color: #529ce8;

  }
}

ul li a.active {
  
}

nav {
  position: fixed;
  top: 0;
  background: none;
}

ul {
  list-style-type: none;
  padding: 0;
  left: 0;
  justify-content: right;
  display: flex;
  gap: 1em;
  background-color: none;
}

.nav-bar {
  position: fixed;
  top: 0;
  width: 100%;
  display: flex;
  background-color: none;
  z-index: 2;
  box-shadow: .1rem 0rem 1rem -0.8rem hsla(0, 0%, 17.6%, 0.3);
  justify-content: left;
  gap: 68%;
}

.nav-content p.title {
  margin: 1.5vh 0px 0px 1vw;
}

.nav-content {
  height: 6vh;
}

.title {
  font-family: 'Roboto Mono', monospace;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  font-size: 22px;
  z-index: 9999;
}

a {
  text-decoration: none;
}

@media only screen and (max-width: 600px) {
  
}


