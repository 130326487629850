

.footer {
  width: 100%;
  color: white;
  text-align: center;
  bottom: 0;
  left: 0;
  flex: 0 0 auto;
  position: fixed;
  height: 10vh;
  z-index: 9999;
  box-shadow: .1rem 0rem 1rem -0.8rem hsla(0, 0%, 17.6%, 0.3);
}

.socials {
  display: flex;
  justify-content: center;
  gap: 5%;
  margin-top: 1.5%;
}

.link {
  text-decoration: none;
  font-size: 18px;
}

.link:hover {
  color: antiquewhite;
  text-decoration: underline;
}

.shutterstock-icon {
  width: 32px;
}

.svg-icon {
  width: 1em;
  height: 1em;
}

.svg-icon path,
.svg-icon polygon,
.svg-icon rect {
  fill: #4691f6;
}

.svg-icon circle {
  stroke: #4691f6;
  stroke-width: 1;
}

.copywrite {
  line-height: 5px;
  color: black;
  font-weight: 100;
  font-size: small;
}

@media only screen and (max-width: 600px) {
  .footer {
    height: 5vh;
  }
}
