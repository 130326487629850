* {
  font-family: 'Roboto Mono', monospace;
  font-optical-sizing: auto;
  font-weight: 400;
  box-sizing: border-box;
}


.experience-content-wrapper {
    margin-top: 10vh;
}

.title h1 {
    display: flex;
    justify-content: center;
    font-size: 4rem;
}